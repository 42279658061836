.controls {
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 100;
}

.controls ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.controls li {
  margin: 0 0 10px;
}

.controls button {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.layers {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}